<template>
  <v-dialog v-model="modal" width="400" persistent>
    <v-card class="container-card">
      <div class="calendar">
        <div class="currentYear">
          <h4 class="mb-0">{{ chosenYear ? chosenYear : currentYear }}</h4>
        </div>
        <div class="years-div">
          <div
            v-for="year in computedYears"
            :key="year"
            class="year common-text"
            :class="selectedYear == year ? 'active-year' : ''"
            @click="selectedYear = year"
            :ref="selectedYear == year ? 'currentYear' : undefined"
          >
            {{ year }}
          </div>
        </div>
        <div class="btn-div py-5">
          <v-btn class="green-btn" @click="chooseYear(selectedYear)"
            >تایید</v-btn
          >
          <v-btn class="red-btn mx-5" @click="closeModal()">انصراف</v-btn>
          <v-btn
            class="outlined-btn"
            @click="
              selectedYear = currentYear;
              scrollToElement();
            "
            >اکنون</v-btn
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment-jalaali";
export default {
  props: ["chosenYear"],
  data() {
    return {
      modal: true,
      years: [],
      moment: moment,
      currentYear: moment(new Date()).format("jYYYY"),
      selectedYear: "",
    };
  },
  computed: {
    computedYears() {
      for (
        let index = Number(this.currentYear) + 100;
        index > Number(this.currentYear) - 100;
        index--
      ) {
        this.years.push(JSON.stringify(index));
      }
      return this.years;
    },
  },
  methods: {
    chooseYear(year) {
      this.$emit("dialogYear", year);
      this.$emit("showModal", false);
      this.modal = false;
    },
    closeModal() {
      this.$emit("showModal", false);
      this.modal = false;
    },
    scrollToElement() {
      setTimeout(() => {
        const [el] = this.$refs.currentYear;
        if (el) {
          el.scrollIntoView({
            block: "center",
            inline: "center",
          });
        } else {
          return;
        }
      }, 50);
    },
  },
  mounted() {
    this.selectedYear = this.chosenYear ? this.chosenYear : this.currentYear;
    this.scrollToElement();
  },
};
</script>

<style lang="scss">
.container-card {
  border-radius: 20px !important;
  .calendar {
    .currentYear {
      border-radius: 20px 20px 0 0 !important;
      width: 100%;
      height: 70px;
      background-color: #068d9d;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
    .years-div {
      width: fit-content;
      height: 280px;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      padding: 15px;
      justify-content: center;
      align-items: center;
      .year {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 60px;
        cursor: pointer;
        margin: 5px 5px;
        color: #455a64;
      }
      .active-year {
        background-color: #068d9d;
        color: #fff;
        border-radius: 10%;
      }
      .year:hover {
        background-color: #068e9d53;
        border-radius: 10%;
      }
    }
    .btn-div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @mixin btnStyle($bgColor) {
        background-color: $bgColor !important;
        box-shadow: none !important;
        border-radius: 10px;
        padding: 20px;
        color: #fff;
        width: 100px;
      }
      .green-btn {
        @include btnStyle(#068e9ddf);
      }
      .red-btn {
        @include btnStyle(#e57373);
      }
      .outlined-btn {
        @include btnStyle(#9575cd);
      }
    }
  }
}
.v-dialog {
  border-radius: 20px !important;
}
</style>
