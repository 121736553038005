<template>
  <div class="report pa-4">
    <v-card class="pe-6 ps-6 py-10 common-card">
      <v-row>
        <v-col class="text-center blue-grey--text text--darken-2 pt-sm-0 pt-6"
          ><h5>گزارش بیمه‌</h5>
        </v-col>
      </v-row>
      <hr class="my-4" />
      <div class="d-md-block d-sm-none">
        <v-stepper v-model="stepNumber" alt-labels>
          <v-stepper-header>
            <v-stepper-step
              :complete="stepNumber > 1"
              :active-icon="'mdi-pen'"
              step="1"
            >
              <div @click="stepNumber = 1" class="text-center pointer">
                <p class="mb-3">گام اول</p>
                <p class="mb-0">انتخاب بیمه‌</p>
                <div v-if="stepNumber > 1" class="c-grey mb-0 mt-3">
                  <span class="common-text" v-if="selectedInsurances.length">
                    ({{ selectedInsurances.length + " بیمه‌" }})
                  </span>
                </div>
              </div>
            </v-stepper-step>
            <v-divider :class="stepNumber > 1 ? 'completed' : ''"></v-divider>

            <v-stepper-step :complete="stepNumber > 2" step="2">
              <div
                @click="
                  stepNumber > 1 && selectedInsurances.length
                    ? (stepNumber = 2)
                    : ''
                "
                :class="
                  stepNumber > 1 && selectedInsurances.length ? 'pointer' : ''
                "
                class="text-center"
              >
                <p class="mb-3">گام دوم</p>
                <p class="mb-0">انتخاب زمان</p>
                <div v-if="stepNumber > 2" class="c-grey mb-0 mt-3">
                  <span class="common-text" v-if="timeType == 'all'"
                    >(گزارش تجمیعی)</span
                  >
                  <span class="common-text" v-if="timeType == 'year'"
                    >(گزارش سال {{ selectedReportYear }})</span
                  >
                  <div class="common-text" v-if="timeType == 'month'">
                    ( گزارش ماه
                    {{ persianMonthName(selectedReportMonth) }}
                    سال
                    {{ selectedMonthYear }}
                    )
                  </div>
                </div>
              </div>
            </v-stepper-step>

            <v-divider :class="stepNumber > 2 ? 'completed' : ''"></v-divider>

            <v-stepper-step step="3">
              <div class="text-center">
                <p class="mb-3">گام سوم</p>
                <p class="mb-0">مشاهده و خروجی</p>
              </div>
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </div>
      <div class="d-md-none d-sm-block pt-4">
        <div class="d-flex flex-row justify-content-center c-grey">
          <div
            class="text-center pointer"
            @click="stepNumber = 1"
            :class="
              stepNumber == 1 ? 'primary--text text--darken-2' : 'primary--text'
            "
          >
            <h6>انتخاب بیمه‌</h6>
            <div v-if="stepNumber > 1" class="c-grey mb-0 mt-3">
              <span class="common-text" v-if="selectedInsurances.length">
                ({{ selectedInsurances.length + " بیمه‌" }})
              </span>
            </div>
          </div>
          <div class="text-center">
            <v-icon class="mx-5">mdi-chevron-left</v-icon>
          </div>
          <div
            class="text-center"
            @click="
              stepNumber > 1 && selectedInsurances.length
                ? (stepNumber = 2)
                : ''
            "
            :class="
              stepNumber > 1 && selectedInsurances.length ? 'pointer' : ''
            "
          >
            <h6
              :class="
                stepNumber == 2
                  ? 'purple--text text--darken-2'
                  : stepNumber > 2
                  ? 'primary--text'
                  : ''
              "
            >
              انتخاب زمان
            </h6>
            <div v-if="stepNumber > 2" class="c-grey mb-0 mt-3">
              <span class="common-text" v-if="timeType == 'all'"
                >(گزارش تجمیعی)</span
              >
              <span class="common-text" v-if="timeType == 'year'"
                >(گزارش سال {{ selectedReportYear }})</span
              >
              <div v-if="timeType == 'month'">
                <p class="mb-1">
                  ( گزارش ماه
                  {{ persianMonthName(selectedReportMonth) }}
                  سال
                  {{ selectedMonthYear }}
                  )
                </p>
              </div>
            </div>
          </div>
          <div>
            <v-icon class="mx-5">mdi-chevron-left</v-icon>
          </div>
          <div>
            <h6
              :class="
                stepNumber == 3
                  ? 'purple--text text--darken-2'
                  : stepNumber > 3
                  ? 'primary--text'
                  : ''
              "
            >
              مشاهده و خروجی
            </h6>
          </div>
        </div>
      </div>
      <hr class="my-4" />
      <!-- Step 1 -->
      <div v-if="stepNumber == 1">
        <div v-if="pageLoading" class="text-center py-16 my-16">
          <fold></fold>
          <p class="blue-grey--text text--darken-2">در حال بارگذاری...</p>
        </div>
        <div v-else>
          <v-row>
            <v-col class="pt-6 text-sm-right text-center">
              <span class="common-text blue-grey--text text--darken-2"
                >بیمه‌ مورد نظر خود را انتخاب کنید.</span
              >
            </v-col>
            <v-col sm="6" cols="12">
              <v-text-field
                rounded
                filled
                clearable
                class="search-box mt-2 mt-sm-0"
                placeholder="نام بیمه‌ را جستجو کنید..."
                v-model="filter"
              >
                <template v-slot:prepend-inner>
                  <v-icon class="me-1 ms-2">fa fa-search</v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <div v-if="!this.filter" class="mt-1 mb-5">
            <v-btn
              @click="!total ? selectAll('insurance') : removeAll('insurance')"
              elevation="0"
              class="all-btn"
              >{{ !total ? "همه" : "هیچ" }}</v-btn
            >
          </div>

          <div
            v-if="!computedInsurances.length"
            class="text-center common-text c-grey"
          >
            <hr />
            موردی یافت نشد.
            <hr />
          </div>

          <v-row>
            <v-col
              lg="3"
              md="3"
              sm="4"
              cols="12"
              v-for="(item, index) in computedInsurances"
              :key="index"
            >
              <div
                class="item-box common-text d-flex flex-column"
                @click="chooseInsurance(item)"
                :class="
                  selectedInsurancesIds.includes(item.id)
                    ? 'active-item-div'
                    : ''
                "
              >
                <h6>{{ item.label }}</h6>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <!-- Step 3 -->
      <div v-if="stepNumber == 2">
        <v-row>
          <v-col class="pt-6 text-sm-right text-center">
            <span class="common-text blue-grey--text text--darken-2"
              >زمان مورد نظر برای گرفتن گزارشات را انتخاب کنید.</span
            >
          </v-col>
        </v-row>
        <v-row class="py-8">
          <v-col md="4" sm="12" cols="12">
            <div
              @click="timeType = 'all'"
              :class="timeType == 'all' ? 'selected-box2' : ''"
              class="common-card h-100 pb-9 b-primary-bold pointer"
            >
              <div class="text-center my-10">
                <p class="primary--text font-weight-bold">تجمیعی</p>
                <p class="c-grey mb-0 mt-14">
                  با انتخاب این گزینه شما قادر به دریافت گزارش تجمیعی هستید.
                </p>
              </div>
            </div>
          </v-col>
          <v-col md="4" sm="12" cols="12">
            <div
              @click="timeType != 'year' ? (showYearModal = true) : ''"
              :class="timeType == 'year' ? 'selected-box2' : 'pointer'"
              class="common-card h-100 pb-9 b-primary-bold"
            >
              <div class="text-center mt-10">
                <p class="primary--text font-weight-bold">تفکیک سال</p>
                <p v-if="timeType != 'year'" class="c-grey mb-0 mt-14 m-10">
                  پس از انتخاب این گزینه و مشخص کردن سال مورد نظر به گام بعدی
                  بروید.
                </p>
                <div v-else class="pt-5">
                  <p class="mb-10">گزارش سال {{ selectedReportYear }}</p>
                  <v-btn
                    @click="showYearModal = true"
                    elevation="0"
                    class="btn-radius white primary--text w-100"
                    height="44"
                    ><span class="common-text font-weight-bold"
                      >تغییر سال</span
                    ></v-btn
                  >
                </div>
              </div>
            </div>
            <year-modal
              v-if="showYearModal"
              :chosenYear="year"
              @dialogYear="getItemYear"
              @showModal="getShowModal"
            ></year-modal>
          </v-col>
          <v-col md="4" sm="12" cols="12">
            <div
              @click="timeType != 'month' ? goToSelectRange() : ''"
              :class="timeType == 'month' ? 'selected-box2' : 'pointer'"
              class="common-card h-100 pb-9 b-primary-bold"
            >
              <div class="text-center mt-10">
                <p class="primary--text font-weight-bold">ماه خاص</p>
                <p v-if="timeType != 'month'" class="c-grey mb-0 mt-14">
                  پس از انتخاب این گزینه و مشخص کردن ماه مورد نظر به گام بعدی
                  بروید.
                </p>
                <div v-else class="pt-5">
                  <p class="mb-10">
                    گزارش ماه
                    {{ persianMonthName(selectedReportMonth) }}
                    سال
                    {{ selectedMonthYear }}
                  </p>
                  <v-btn
                    @click="selectTimeRangeDialog = true"
                    elevation="0"
                    class="btn-radius white primary--text w-100"
                    height="44"
                    ><span class="common-text font-weight-bold"
                      >تغییر ماه</span
                    ></v-btn
                  >
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-dialog v-model="selectTimeRangeDialog" width="800">
          <v-card
            class="modal-card"
            style="max-height: 500px; overflow-y: auto"
          >
            <v-container class="pa-5">
              <v-row class="mb-1">
                <v-col class="text-right">
                  <span class="common-text blue-grey--text text--darken-2 pt-1"
                    >ماه و سال مورد نظر خود را انتخاب کنید:</span
                  >
                </v-col>
                <v-col cols="2" class="text-left">
                  <span
                    class="common-text"
                    @click="selectTimeRangeDialog = false"
                  >
                    <v-icon class="pointer">$Close</v-icon>
                  </span>
                </v-col>
              </v-row>
              <div class="py-4">
                <v-row>
                  <v-col>
                    <span class="blue-grey--text text--darken-2 pt-3 pb-4"
                      >سال:</span
                    >
                    <span id="dateFrom">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="calendar_today"
                        v-model="selectedMonthYear"
                        :editable="true"
                        class="date-input mt-2"
                        hide-details
                        @focus="showYearModal2 = true"
                      >
                      </v-text-field>
                    </span>

                    <year-modal
                      v-if="showYearModal2"
                      :chosenYear="firstSelectedMonthYear"
                      @dialogYear="getItemYearForMonth"
                      @showModal="getShowModalForMonth"
                    ></year-modal>
                  </v-col>
                  <v-col>
                    <span class="blue-grey--text text--darken-2 pt-3 pb-4"
                      >ماه:</span
                    >
                    <span id="dateTo">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="calendar_today"
                        v-model="selectedReportMonth"
                        :editable="true"
                        class="date-input mt-2"
                        hide-details
                      >
                      </v-text-field>
                    </span>

                    <date-picker
                      v-model="selectedReportMonth"
                      element="dateTo"
                      color="#00a7b7"
                      type="month"
                    />
                  </v-col>
                </v-row>
                <h6
                  class="primary--text mt-5 text-right"
                  v-if="selectedReportMonth && selectedMonthYear"
                >
                  ماه انتخاب شده:
                  <span class="blue-grey--text text--darken-2">
                    {{ persianMonthName(selectedReportMonth) }}
                    سال
                    {{ selectedMonthYear }}
                  </span>
                </h6>
              </div>
              <div class="pt-4 text-end">
                <v-btn
                  class="primary-btn"
                  width="100"
                  height="47"
                  :disabled="!selectedMonthYear || !selectedReportMonth"
                  @click="
                    selectTimeRangeDialog = false;
                    timeType = 'month';
                  "
                  >تایید</v-btn
                >
              </div>
            </v-container>
          </v-card>
        </v-dialog>
        <v-dialog v-model="selectDayModal" width="300">
          <v-card class="modal-card pa-0">
            <div v-if="dateType == 'from'">
              <v-card-title class="primary py-4">
                <span
                  class="mx-auto c-light text-center"
                  style="font-size: 2rem"
                  >{{ range.fromDay }}</span
                >
              </v-card-title>
              <div class="fit-content pa-5">
                <div
                  v-for="(number, index) in fromNumberOfDay"
                  :key="index"
                  class="day-box d-inline-block pointer"
                  :class="parseInt(range.fromDay) == number ? 'selected' : ''"
                  @click="
                    selectDayModal = false;
                    range.fromDay = number;
                  "
                >
                  {{ number }}
                </div>
              </div>
            </div>
            <div v-else>
              <v-card-title class="primary py-4">
                <span
                  class="mx-auto c-light text-center"
                  style="font-size: 2rem"
                  >{{ range.toDay }}</span
                >
              </v-card-title>
              <div class="fit-content pa-5">
                <div
                  v-for="(number, index) in toNumberOfDay"
                  :key="index"
                  class="day-box d-inline-block"
                  :class="parseInt(range.toDay) == number ? 'selected' : ''"
                >
                  <div
                    v-if="
                      range.fromYear == range.toYear &&
                      range.fromMonth == range.toMonth
                    "
                  >
                    <div
                      class="pointer"
                      @click="
                        selectDayModal = false;
                        range.toDay = number;
                      "
                      v-if="index >= parseInt(range.fromDay) - 1"
                    >
                      {{ number }}
                    </div>
                    <div v-else class="disabled">
                      {{ number }}
                    </div>
                  </div>
                  <div
                    class="pointer"
                    @click="
                      selectDayModal = false;
                      range.toDay = number;
                    "
                    v-else
                  >
                    {{ number }}
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
      <!-- Step 4 -->
      <div v-if="stepNumber == 3" class="pb-6">
        <div v-if="pageLoading" class="text-center py-16 my-16">
          <fold></fold>
          <p class="blue-grey--text text--darken-2">در حال بارگذاری...</p>
        </div>
        <div v-else>
          <v-row>
            <v-col v-if="selectedInsurances.length" class="pt-6 text-end">
              <v-btn
                @click="selectedInsurancesDialog = true"
                class="secondary-btn"
              >
                <span class="common-text">
                  {{ selectedInsurances.length }} بیمه‌</span
                >
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-tabs v-model="tab">
                <v-tab
                  class="primary--text common-text tab-title"
                  v-if="deviceType == 'desktop'"
                  >نمودار‌ها</v-tab
                >
                <!--<v-tab class="primary--text">جدول‌ها</v-tab>-->
              </v-tabs>
              <v-tabs-items v-model="tab">
                <!-- نمودار‌ها -->
                <v-tab-item v-if="deviceType == 'desktop'">
                  <v-container class="pa-3">
                    <!--<div class="text-center common-text" v-if="computedCharts">
                      <hr />
                      موردی برای نمایش وجود ندارد.
                      <hr />
                    </div>-->
                    <div v-if="deviceType == 'desktop'">
                      <div>
                        <!--<div class="mb-4 buttons-container" v-if="loaded">
                          <v-btn
                            v-if="chartStep != 1"
                            @click="fetchAllInsurancesData"
                            class="primary-btn ms-1 me-1"
                            dark
                            >گزارش عمومی</v-btn
                          >
                          <v-btn
                            v-if="chartStep > 2"
                            @click="
                              fetchInsuranceYearlyData(selectedInsuranceName)
                            "
                            class="primary-btn ms-1 me-1"
                            dark
                            >گزارش سالانه بیمه‌
                          </v-btn>
                          <v-btn
                            v-if="chartStep > 3"
                            @click="
                              fetchInsuranceMonthlyData(selectedReportYear)
                            "
                            class="primary-btn ms-1 me-1"
                            dark
                            >گزارش ماهانه بیمه‌
                          </v-btn>
                          <v-btn
                            @click="capture"
                            class="primary-btn ms-1 me-1"
                            dark
                            :loading="!showTitle"
                            :disabled="!loaded"
                          >
                            دریافت عکس نمودار‌ها</v-btn
                          >
                          <a
                            v-show="false"
                            class="btn btn-primary"
                            id="download"
                            >Download</a
                          >
                        </div>-->

                        <div id="capture">
                          <div v-if="showTitle && loaded">
                            <p
                              style="text-align: right; direction: rtl"
                              v-if="chartStep == 1"
                            >
                              جهت مشاهده جزییات هر بیمه‌ روی بیمه‌ مورد نظر کلیک
                              کنید.
                            </p>
                            <p
                              style="text-align: right; direction: rtl"
                              v-if="chartStep == 2"
                            >
                              جهت مشاهده جزئیات روی سال مورد نظر کلیک کنید.
                            </p>
                            <p
                              style="text-align: right; direction: rtl"
                              v-if="chartStep == 3"
                            >
                              جهت مشاهده جزئیات روی ماه مورد نظر کلیک کنید.
                            </p>
                            <!--<p
                              style="text-align: right; direction: rtl"
                              v-if="chartStep == 4"
                            >
                              جهت مشاهده جزئیات روی روز مورد نظر کلیک کنید.
                            </p>
                            <p
                              style="text-align: right; direction: rtl"
                              v-if="chartStep == 5"
                            >
                              جهت مشاهده گزارش هر شیفت روی آن کلیک کنید.
                            </p>-->
                          </div>
                          <v-row>
                            <v-col>
                              <bar-chart
                                v-if="loaded"
                                :chartdata="dataBar"
                                :options="optionsBar2"
                              >
                              </bar-chart>
                              <div v-else class="text-center pa-5">
                                <v-progress-circular
                                  :size="50"
                                  :width="7"
                                  color="primary"
                                  indeterminate
                                ></v-progress-circular>
                                <h6 class="mt-8 primary--text text--darken-1">
                                  در حال بارگذاری...
                                </h6>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </div>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
          <v-dialog v-model="selectedInsurancesDialog" width="600">
            <v-card class="modal-card">
              <v-container class="pa-5">
                <v-row class="mb-1">
                  <v-col class="text-right">
                    <span class="common-text primary--text pt-1"
                      >بیمه‌‌های انتخاب شده:</span
                    >
                  </v-col>
                  <v-col class="text-left">
                    <span
                      class="common-text"
                      @click="selectedInsurancesDialog = false"
                    >
                      <v-icon class="pointer">$Close</v-icon>
                    </span>
                  </v-col>
                </v-row>
                <div style="max-height: 400px; overflow-y: auto">
                  <div
                    v-for="(item, index) in selectedInsurances"
                    :key="index"
                    class="my-4 c-grey d-flex flex-row"
                  >
                    <li class="pa-4 blue-grey--text text--darken-2 common-text">
                      {{ item }}
                    </li>
                  </div>
                </div>
              </v-container>
            </v-card>
          </v-dialog>
        </div>
      </div>

      <v-row v-if="!pageLoading" class="mt-8 w-100">
        <v-col
          class="text-right pe-0 ps-0"
          v-if="stepNumber < 3"
          md="4"
          sm="6"
          cols="12"
        >
          <v-btn
            class="purple-btn text-white no-underline btn-radius w-100 ms-3"
            elevation="0"
            height="43"
            @click="nextStep()"
            :disabled="computedNextButton"
            >گام بعدی</v-btn
          >
        </v-col>
        <v-spacer v-if="stepNumber < 3"></v-spacer>
        <v-col class="text-left pe-0 ps-0" md="4" sm="6" cols="12">
          <v-btn
            class="secondary-btn no-underline btn-radius w-100 ms-3"
            elevation="0"
            height="43"
            @click="previousStep()"
            v-if="stepNumber > 1"
            >گام قبلی</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <!--<div class="goToDown" @click="goDown()">
      <v-icon>mdi-chevron-down</v-icon>
    </div>-->
  </div>
</template>

<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import moment from "moment-jalaali";
import { mapGetters } from "vuex";
import BarChart from "/src/components/charts/BarExample.vue";
import PieChart from "/src/components/charts/PieExample.vue";
import html2canvas from "html2canvas";
import YearModal from "@/components/YearModal";
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    YearModal,
    BarChart,
    PieChart,
  },
  data() {
    return {
      filter: "",
      vLoading: false,
      insurances: [],
      isSelected: false,
      selectedInsurances: [],
      selectedInsurancesIds: [],
      total: false,
      stepNumber: 1,
      projects: {
        items1: [],
        items2: [],
        items3: [],
        items4: [],
        items5: [],
        items6: [],
        items7: [],
        items8: [],
      },
      timeType: "",
      selectYearDialog: false,
      selectTimeRangeDialog: false,
      selectedInsurancesDialog: false,
      selectDayModal: false,
      moment: moment,
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      year: moment(new Date()).format("jYYYY"),
      currentYear: moment(new Date()).format("jYYYY"),
      range: {
        fromYear: parseInt(moment(new Date()).format("jYYYY")) - 1,
        fromMonth: moment(new Date()).format("jMM"),
        fromDay: moment(new Date()).format("jDD"),
        toYear: moment(new Date()).format("jYYYY"),
        toMonth: moment(new Date()).format("jMM"),
        toDay: moment(new Date()).format("jDD"),
      },
      monthsName: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      monthsNumbers: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      items: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      barCurrentPage1: 1,
      barTotalRows1: "",
      barCurrentPage2: 1,
      barTotalRows2: "",
      barCurrentPage3: 1,
      barTotalRows3: "",
      barFields: [
        // { key: "index", label: "#" },
        { key: "name", label: "عنوان" },
        { key: "data", label: "تعداد" },
        { key: "data2", label: "تعداد کل" },
      ],
      excelBarFields: [
        { field: "name", label: "عنوان" },
        { field: "data", label: "تعداد" },
        { field: "data2", label: "تعداد کل" },
      ],
      barFields2: [
        // { key: "index", label: "#" },
        { key: "name", label: "سال" },
        { key: "data", label: "تعداد" },
        { key: "data2", label: "تعداد کل" },
      ],
      excelBarFields2: [
        // { key: "index", label: "#" },
        { field: "name", label: "سال" },
        { field: "data", label: "تعداد" },
        { field: "data2", label: "تعداد کل" },
      ],
      currentPage: 1,
      totalRows: "",
      perPage: 10,
      barCurrentPage: 1,
      barTotalRows: "",
      pieCurrentPage: 1,
      pieTotalRows: "",
      currentPage2: 1,
      projectCurrentPage: 1,
      totalRows2: "",
      projectTotalRows: {
        items1: null,
        items2: null,
        items3: null,
        items4: null,
        items5: null,
        items6: null,
        items7: null,
        items8: null,
      },
      perPage2: 10,
      tableBusy: false,
      items1: [],
      items2: [],
      selectedRange: {},
      fromNumberOfDay: null,
      toNumberOfDay: null,
      dateType: "",
      tab: null,
      barBusy: false,
      doughnutBusy: false,
      barLoaded: false,
      dataBar: {
        labels: [],
        datasets: [
          {
            label: "",
            backgroundColor: [],
            data: [],
          },
        ],
      },

      optionsBar2: {
        title: {
          display: true,
          fontSize: 16,
          position: "top",
          fontFamily: "projectMainFont",
          text: "",
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "تعداد",
                fontFamily: "projectMainFont",
              },
              ticks: {
                beginAtZero: true,
                fontFamily: "projectMainFont",
              },
              stacked: false,
            },
          ],
          xAxes: [
            {
              stacked: false,
              ticks: {
                fontFamily: "projectMainFont",
              },
            },
          ],
        },
        legend: {
          display: true,
          position: "top",
          labels: {
            fontFamily: "projectMainFont",
          },
        },
        tooltips: {
          titleFontFamily: "projectMainFont",
          bodyFontFamily: "projectMainFont",
        },
        onClick: this.refreshChart,
      },
      deviceType: "",
      pageLoading: false,
      reactivity: true,
      allData: {},
      barTable: [],
      pieTable: [],
      showYearModal: false,
      showYearModal2: false,
      showYearFromModal: false,
      showYearToModal: false,
      selectedReportType: "",
      faSelectedReportType: "",
      reportTypes: [
        {
          enName: "servicesNum",
          name: "تعداد کل ویزیت و خدمات",
        },
        {
          enName: "servicesPrice",
          name: "مبلغ کل ویزیت و خدمات",
        },
        {
          enName: "servicesNumPerHour",
          name: "تعداد ویزیت و خدمات به ازای ساعت کارکرد",
        },
        {
          enName: "servicesPricePerHour",
          name: "مبلغ ویزیت و خدمات به ازای ساعت کارکرد",
        },
      ],
      dateFrom: moment(new Date()).subtract(30, "day").format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      chartStep: 1,
      showTitle: true,
      picName: "گزارش ۶ ماه گذشته",
      loaded: false,
      monthDic: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      selectedInsuranceId: "",
      firstChartLabels: [],
      firstChartIds: [],
      selectedInsuranceName: "",
      selectedYear: "",
      selectedReportYear: "",
      selectedDay: "",
      daysDifference: "",
      selectedMonth: "",
      selectedReportMonth: "",
      selectedMonthYear: "",
      firstSelectedMonthYear: "",
      globalMonth: "",
      shiftIds: [],
    };
  },
  methods: {
    selectReportType(reportType) {
      this.selectedReportType = reportType.enName;
      this.faSelectedReportType = reportType.name;
    },
    getItemYear(value) {
      this.year = value;
      this.selectedReportYear = this.year;
      this.timeType = "year";
    },
    getShowModal(value) {
      this.showYearModal = value;
    },
    getItemYearForMonth(value) {
      this.firstSelectedMonthYear = value;
      this.selectedMonthYear = this.firstSelectedMonthYear;
    },
    getShowModalForMonth(value) {
      this.showYearModal2 = value;
    },
    persianMonthName(month) {
      let monthName;
      monthName = this.monthsName[Number(month) - 1];
      return monthName;
    },
    returnMonthNumber(month) {
      let monthNumber;
      monthNumber = this.monthsName.indexOf(month);
      if (monthNumber + 1 < 10) {
        return "0" + (monthNumber + 1);
      } else {
        return monthNumber + 1;
      }
    },
    // getLogo(item) {
    //   if (this.university.filter((x) => x.id == item)) {
    //     return this.university.filter((x) => x.id == item)[0].url;
    //   } else return "";
    // },
    goDown() {
      var container = document.querySelector("html");
      var scrollHeight = container.scrollHeight;
      container.scrollTop = scrollHeight;
    },
    chooseInsurance(item) {
      if (
        this.selectedInsurances.length &&
        this.selectedInsurances.includes(item.label)
      ) {
        let index;
        index = this.selectedInsurances.indexOf(item.label);
        this.selectedInsurances.splice(index, 1);
        this.selectedInsurancesIds.splice(index, 1);
      } else {
        this.selectedInsurances.push(item.label);
        this.selectedInsurancesIds.push(item.id);
      }

      if (
        this.selectedInsurances.length ==
        this.insurTypes.filter((x) => x.label != "آزاد").length
      ) {
        this.total = true;
      } else {
        this.total = false;
      }
    },
    selectAll(type) {
      if (type == "insurance") {
        this.insurTypes
          .filter((x) => x.label != "آزاد")
          .forEach((x) => {
            if (!this.selectedInsurances.includes(x.label)) {
              this.selectedInsurances.push(x.label);
              this.selectedInsurancesIds.push(x.id);
            }
          });
        this.total = true;
      }
      this.goDown();
    },
    removeAll(type) {
      if (type == "insurance") {
        this.selectedInsurances = [];
        this.selectedInsurancesIds = [];
        this.total = false;
      }
    },
    nextStep() {
      this.filter = "";
      ++this.stepNumber;
      if (this.stepNumber == 3) {
        this.tab = 0;
        this.fetchAllInsurancesData();
      }

      setTimeout(() => {
        document.querySelector("html").scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 100);
    },
    previousStep() {
      this.filter = "";
      if (this.stepNumber == 3 && !this.selectedInsurances.length) {
        this.stepNumber = 1;
      } else {
        --this.stepNumber;
      }

      setTimeout(() => {
        document.querySelector("html").scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 100);
    },

    goToSelectRange() {
      this.selectTimeRangeDialog = true;
      this.getNumberOfDays("from");
      this.getNumberOfDays("to");
    },
    //changeDate(type) {
    //  if (type == "year") {
    //    if (this.range.fromYear > this.range.toYear) {
    //      this.range.toYear = this.range.fromYear;
    //    }
    //    if (this.range.fromYear == this.range.toYear) {
    //      if (this.range.fromMonth > this.range.toMonth) {
    //        this.range.toMonth = this.range.fromMonth;
    //      }
    //    }
    //    if (
    //      this.range.fromYear == this.range.toYear &&
    //      this.range.fromMonth == this.range.toMonth
    //    ) {
    //      if (this.range.fromDay > this.range.toDay) {
    //        this.range.toDay = this.range.fromDay;
    //      }
    //    }
    //  }
    //  if (type == "month") {
    //    if (this.range.fromYear == this.range.toYear) {
    //      if (this.range.fromMonth > this.range.toMonth) {
    //        this.range.toMonth = this.range.fromMonth;
    //      }
    //      if (this.range.fromMonth == this.range.toMonth) {
    //        if (this.range.fromDay > this.range.toDay) {
    //          this.range.toDay = this.range.fromDay;
    //        }
    //      }
    //    }
    //  }
    //  if (type == "day") {
    //    if (this.range.fromYear == this.range.toYear) {
    //      if (this.range.fromMonth == this.range.toMonth) {
    //        if (this.range.fromDay > this.range.toDay) {
    //          this.range.toDay = this.range.fromDay;
    //        }
    //      }
    //    }
    //  }
    //},
    getNumberOfDays(type) {
      if (type == "from") {
        if (1 <= parseInt(this.range.fromMonth) < 7) {
          this.fromNumberOfDay = 31;
        }
        if (
          7 <= parseInt(this.range.fromMonth) &&
          parseInt(this.range.fromMonth) != 12
        ) {
          this.fromNumberOfDay = 30;
        }
        if (parseInt(this.range.fromMonth) == 12) {
          let isLead = moment(
            this.range.fromYear + "/12/30",
            "jYYYY/jMM/jDD"
          ).isValid();
          if (isLead) {
            this.fromNumberOfDay = 30;
          } else this.fromNumberOfDay = 29;
        }

        if (parseInt(this.range.fromDay) > parseInt(this.fromNumberOfDay)) {
          this.range.fromDay = this.fromNumberOfDay;
        }
      } else {
        if (1 <= parseInt(this.range.toMonth) < 7) {
          this.toNumberOfDay = 31;
        }
        if (
          7 <= parseInt(this.range.toMonth) &&
          parseInt(this.range.toMonth) != 12
        ) {
          this.toNumberOfDay = 30;
        }
        if (parseInt(this.range.toMonth) == 12) {
          let isLead = moment(
            this.range.toYear + "/12/30",
            "jYYYY/jMM/jDD"
          ).isValid();
          if (isLead) {
            this.toNumberOfDay = 30;
          } else this.toNumberOfDay = 29;
        }

        if (parseInt(this.range.toDay) > parseInt(this.toNumberOfDay)) {
          this.range.toDay = this.toNumberOfDay;
        }
      }
    },
    capture() {
      this.showTitle = false;
      setTimeout(() => {
        html2canvas(document.querySelector("#capture"))
          .then((canvas) => {
            var button = document.getElementById("download");
            button.setAttribute("href", canvas.toDataURL("image/png", 1));
            button.setAttribute("download", this.picName);
          })
          .then(() => {
            var button = document.getElementById("download");
            button.click();
            setTimeout(() => {
              this.showTitle = true;
            }, 500);
          });
      }, 500);
    },

    refreshChart(evt, item) {
      if (this.chartStep == 1) {
        this.selectedInsuranceName = item[0]["_model"].label;
        var insuranceIndex = this.firstChartLabels.indexOf(
          this.selectedInsuranceName
        );
        this.selectedInsuranceId = this.firstChartIds[insuranceIndex];
      } else {
        var label = item[0]["_model"].label;
      }
      switch (this.chartStep) {
        case 1:
          this.fetchInsuranceYearlyData();
          break;
        case 2:
          this.fetchInsuranceMonthlyData(label);
          break;
        case 3:
          this.fetchInsuranceDayData(label);
          break;
        case 4:
          this.fetchInsuranceShiftData(label);
          break;
        case 5:
          this.gotoShift(item[0]["_index"]);
          break;
        default:
          break;
      }
    },
    // get data for charts about all insurances
    fetchAllInsurancesData() {
      let reportTimeType = "";
      if (this.timeType == "all") {
        reportTimeType = "گزارش تجمیعی";
      } else if (this.timeType == "year") {
        reportTimeType = `گزارش سال ${this.selectedReportYear}`;
      } else if (this.timeType == "month") {
        reportTimeType = `گزارش ماه ${this.persianMonthName(
          this.selectedReportMonth
        )} سال ${this.selectedMonthYear}`;
      }
      this.picName = `گزارش بیمه (${reportTimeType})`;
      this.loaded = false;
      this.chartStep = 1;
      this.$http
        .post(
          this.baseUrl + "/clinic/admin/reports/insurances",
          {
            insuranceIds: this.selectedInsurancesIds,
            year:
              this.timeType == "year"
                ? this.year
                : this.timeType == "month"
                ? this.selectedMonthYear
                : "",
            month: this.timeType == "month" ? this.selectedReportMonth : "",
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.dataBar = res.data.results;
            this.firstChartLabels = res.data.results.labels;
            this.firstChartIds = res.data.insuranceIds;
            this.optionsBar2.title.text = reportTimeType;

            this.loaded = true;
          } else {
            this.toast(
              "خطایی در سامانه رخ داده است، لطفا دوباره تلاش نمایید",
              "error"
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    // click on one insurance to show its yearly data
    fetchInsuranceYearlyData() {
      this.picName = `گزارش سالانه بیمه ${this.selectedInsuranceName}`;
      this.loaded = false;
      this.$http
        .post(
          this.baseUrl + "/clinic/admin/reports/insurancebyyears",
          {
            insuranceId: this.selectedInsuranceId,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.dataBar = res.data.results;
            this.optionsBar2.title.text = `نمودار سالانه بیمه ${this.selectedInsuranceName}`;

            this.chartStep = 2;

            this.loaded = true;
          } else {
            this.toast(
              "خطایی در سامانه رخ داده است، لطفا دوباره تلاش نمایید",
              "error"
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    // click on a years to show its monthly data
    fetchInsuranceMonthlyData(date) {
      this.loaded = false;
      this.picName = `گزارش ماهانه سال ${date} بیمه ${this.selectedInsuranceName}`;
      this.selectedYear = date;
      this.$http
        .post(
          this.baseUrl + "/clinic/admin/reports/insurancepermonths",
          {
            insuranceId: this.selectedInsuranceId,
            year: date,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.dataBar = res.data.results;
            this.optionsBar2.title.text = `گزارش ماهانه سال ${date} بیمه ${this.selectedInsuranceName}`;

            this.chartStep = 3;
            this.loaded = true;
          } else {
            this.toast(
              "خطایی در سامانه رخ داده است، لطفا دوباره تلاش نمایید",
              "error"
            );
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    // click on a month to show its daily data
    fetchInsuranceDayData(date) {
      this.loaded = false;
      this.globalMonth = this.returnMonthNumber(date);
      this.selectedMonth = this.selectedYear + "/" + this.globalMonth;
      this.picName = `گزارش ${this.faSelectedReportType} بیمه ${this.selectedInsuranceName} در ${this.selectedMonth}`;
      this.$http
        .post(
          this.baseUrl + "/clinic/admin/reports/insuranceperdays",
          {
            insuranceId: this.selectedInsuranceId,
            date: this.selectedMonth,
            year: this.selectedYear,
            month: this.globalMonth,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.dataBar = res.data.results;
            this.optionsBar2.title.text = `نمودار ${this.faSelectedReportType} بیمه ${this.selectedInsuranceName} در ${this.selectedMonth}`;

            this.chartStep = 4;
            this.loaded = true;
          } else {
            this.toast(
              "خطایی در سامانه رخ داده است، لطفا دوباره تلاش نمایید",
              "error"
            );
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    // click on a day to show its shift data
    fetchInsuranceShiftData(date) {
      this.loaded = false;
      this.selectedDay = this.selectedMonth + "/" + date;
      this.picName = `گزارش شیفت‌های تاریخ ${this.selectedDay} بیمه ${this.selectedInsuranceName}`;
      this.$http
        .post(
          this.baseUrl + "/clinic/admin/reports/insurancepershifts",
          {
            year: this.selectedYear,
            month: this.globalMonth,
            day: date,
            insuranceId: this.selectedInsuranceId,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.dataBar = res.data.results;
            this.shiftIds = res.data.shiftIds;
            this.optionsBar2.title.text = `گزارش شیفت‌های تاریخ ${this.selectedDay} بیمه ${this.selectedInsuranceName}`;
            this.chartStep = 5;
            this.loaded = true;
          } else {
            this.toast(
              "خطایی در سامانه رخ داده است، لطفا دوباره تلاش نمایید",
              "error"
            );
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    gotoShift(index) {
      //this.$router.push('/admin/shiftReport/'+this.dataBar.datasets[0].shiftIds[index])
      let link = "/admin/shiftReport/" + this.shiftIds[index];
      window.open(link, "_blank");
    },
  },
  computed: {
    ...mapGetters(["insurTypes"]),
    computedInsurances() {
      if (this.filter) {
        let filtered;
        filtered = this.insurTypes
          .filter((x) => x.label != "آزاد")
          .filter((x) => x.label.includes(this.filter));
        return filtered;
      } else {
        return this.insurTypes.filter((x) => x.label != "آزاد");
      }
    },
    computedNextButton() {
      if (this.stepNumber == 1) {
        if (!this.selectedInsurances.length) {
          return true;
        } else return false;
      }
      if (this.stepNumber == 2) {
        if (!this.timeType) {
          return true;
        } else return false;
      }
    },
    computedCharts() {
      if (!this.allData.barChartHasData && !this.allData.pieChartHasData) {
        return true;
      } else return false;
    },
    computedNumOfDays() {
      let firstDate = moment(this.dateFrom, "jYYYY/jMM/jDD-HH:mm").format("X");
      let secondDate = moment(this.dateTo, "jYYYY/jMM/jDD-HH:mm").format("X");
      const timeDifference = secondDate - firstDate;
      this.daysDifference = Math.floor(timeDifference / 86400);
      if (this.daysDifference > 0) {
        return this.daysDifference + 1;
      } else {
        return "لطفا تاریخ را به درستی انتخاب کنید.";
      }
    },
  },
  mounted() {
    if (window.screen.width < 500) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 500) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    //this.gettingPic = [false, false, false];
  },
};
</script>
<style lang="scss">
.modal-card {
  border-radius: 10px !important;
  .edit-icon {
    path {
      stroke: #068d9d !important;
    }
    transform: translateY(-2px);
  }
  .v-card__title {
    border-radius: 10px 10px 0 0;
  }
  .day-box {
    width: 37px;
    height: 37px;
    text-align: center;
    border-radius: 50%;
    padding-top: 8px;
    color: #656a81;
    &:hover {
      background-color: #eeeeee;
    }
    &.selected {
      background-color: #e6e6e6;
      color: #068d9d;
      font-weight: bold;
    }
  }
  .small-logoBox {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
button {
  outline: none !important;
}
.completed {
  &.v-divider {
    height: 8px !important;
    max-height: 8px !important;
    border-radius: 5px !important;
    background-color: red !important;
    border: none !important;
  }
}
</style>
